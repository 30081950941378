<template>
  <div class="register" v-show="show">
    <CredentialsHeader>
      <template v-slot:left>
        <a v-if="showBack" @click="goBack()" class="registration-back-button">
          <ArrowBack />
          Back
        </a>
      </template>
      <template v-slot:center>
        <HeaderLogo />
        <RegistrationSteps
          v-if="showRegistrationSteps"
          :steps="steps"
          :currentRegistrationStep="currentRegistrationStep"
          @onStepChanged="onStepChanged"
        ></RegistrationSteps>
      </template>
      <template v-slot:right>
        <div class="header-text d-none d-md-block">
          <span class="text">
            <b-link @click="goToSignIn()">Sign In</b-link>
          </span>
        </div>
        <div class="header-text d-block d-md-none">
          <b-link @click="goToSignIn()">Sign In</b-link>
        </div>
      </template>
    </CredentialsHeader>

    <div class="mp-container">
      <transition name="fade">
        <div class="credential-wrapper">
          <router-view
            @onServingsSelected="onServingsSelected"
            @onIntolerancesSelected="onIntolerancesSelected"
            @onMealsSelected="onMealsSelected"
            @onRegister="onRegister"
            @onCheckout="onCheckout"
          ></router-view>
          <ButtonSkip
            v-if="currentRegistrationStep === 1"
            :step="currentRegistrationStep"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { ArrowBack } from '@/components/common/MPIcons'
import CredentialsHeader from '@/components/credentials/CredentialsHeader'
import HeaderLogo from '@/components/credentials/HeaderLogo'
import RegistrationSteps from '@/components/credentials/register/RegistrationSteps'
import credentialsHandler from '@/handlers/CredentialsHandler'
import LoadingMixin from '@/mixins/LoadingMixin'
import ExternalServices from '@/externalServices'
import { hideIntercomWidget } from '@/utils/intercom'
import DetectDeviceMixin from '@/mixins/DetectDeviceMixin'
import ButtonSkip from '@/components/credentials/register/ButtonSkip'
import { experiments, startExperiment } from '@/utils/experiments'
import FullStory from '@/externalServices/FullStory'
import ChargebeeMixin from '@/mixins/ChargebeeMixin'
import NotificationsMixin from '@/mixins/NotificationsMixin'
import apiCredentials from '@/api/credential'
import ServerError from '@/components/common/Notifications/ServerError.vue'
import NoPlanError from '@/components/common/Notifications/NoPlanError.vue'
import UserAlreadyExists from '@/components/common/Notifications/UserAlreadyExists.vue'
import AlreadyRegistered from '@/components/common/Notifications/AlreadyRegistered.vue'
import { EVENTS } from '@/events/analytics'

export default {
  mixins: [LoadingMixin, DetectDeviceMixin, ChargebeeMixin, NotificationsMixin],
  name: 'ChehckoutRegister',
  data() {
    return {
      show: false,
      showStepsOnFirstStep: false,
      coupons: [],
      isRegistered: false,
    }
  },
  components: {
    CredentialsHeader,
    HeaderLogo,
    RegistrationSteps,
    ArrowBack,
    ButtonSkip,
  },
  computed: {
    ...mapGetters({
      steps: 'registration/registrationSteps',
      currentRegistrationStep: 'registration/currentRegistrationStep',
      getRegistrationStepsToResume: 'registration/getRegistrationStepsToResume',
      adultServings: 'registration/adultServings',
      childServings: 'registration/childServings',
      soyIntolerance: 'registration/soyIntolerance',
      glutenIntolerance: 'registration/glutenIntolerance',
      nutsIntolerance: 'registration/nutsIntolerance',
      breakfastOptin: 'registration/breakfastOptin',
      lunchOptin: 'registration/lunchOptin',
      dinnerOptin: 'registration/dinnerOptin',
      snackOptin: 'registration/snackOptin',
      dessertOptin: 'registration/dessertOptin',
      additionalIntolerances: 'registration/additionalIntolerances',
      email: 'registration/email',
      fullName: 'registration/fullName',
      password: 'registration/password',
      generalEmailsOptin: 'registration/generalEmailsOptin',
      loginURL: 'globals/loginURL',
      affiliateCode: 'globals/affiliateCode',
      selectedPlan: 'checkout/selectedPlan',
      userPreferences: 'registration/userPreferences',
      planError: 'globals/noPlanError',
    }),
    isInitialRegistrationStep() {
      return this.$route.path.match(/(register\/?$|register\/1$)/)
    },
    showBack() {
      return (
        !this.isInitialRegistrationStep &&
        this.steps.length !== this.currentRegistrationStep
      )
    },
    showRegistrationSteps() {
      return !this.isInitialRegistrationStep || this.showStepsOnFirstStep
    },
    separateName() {
      const arrayName = this.fullName.split(' ', 2)
      return {
        firstname: arrayName[0],
        lastname: arrayName[1] ? arrayName[1] : '',
      }
    },
  },
  methods: {
    ...mapActions({
      loadCheckout: 'checkout/loadCheckoutPage',
      setPlanId: 'checkout/setPlanId',
      goBack: 'registration/previousRegistrationStep',
      setStepDone: 'registration/setRegistrationStepDone',
      nextStep: 'registration/nextRegistrationStep',
      onStepChanged: 'registration/setRegistrationStep',
      setUserEmail: 'registration/setUserEmail',
      setUserFullname: 'registration/setUserFullName',
      setUserPassword: 'registration/setUserPassword',
      setMailOptin: 'registration/setUserGeneralEmailsOptin',
      registerWithSub: 'registration/registerWithSubscription',
      refreshUser: 'user/refreshUserData',
      setLoading: 'globals/setLoading',
    }),
    goToSignIn() {
      this.$router.push(this.loginURL)
    },
    goToNextStep() {
      this.setStepDone()
      this.nextStep()
    },
    onServingsSelected() {
      const serving_meal = {
        child_servings: this.childServings,
        adult_servings: this.adultServings,
      }
      this.goToNextStep()
    },
    onIntolerancesSelected() {
      const allergies = {
        soy_intolerance: this.soyIntolerance,
        glutten_intolerance: this.glutenIntolerance,
        nuts_intolerance: this.nutsIntolerance,
      }
      this.goToNextStep()
    },
    onMealsSelected() {
      const meal_selected = {
        breakfast_option: this.breakfastOptin,
        lunch_option: this.lunchOptin,
        dinner_option: this.dinnerOptin,
        snack_option: this.snackOptin,
        dessert_option: this.dessertOptin,
      }
      this.goToNextStep()
    },
    async onRegister() {
      if (!this.isRegistered) {
        try {
          this.setLoading(true)
          await apiCredentials.createFreeUser(
            this.fullName,
            this.email,
            this.password,
            this.generalEmailsOptin,
            this.userPreferences
          )
          this.isRegistered = true
          this.$posthog.sendEvent(EVENTS.registration.createAccount, {
            'newsletter opt-in': this.generalEmailsOptin,
          })
          this.setLoading(false)
          this.goToNextStep()
        } catch (error) {
          this.setLoading(false)
          if (error.code === 422) {
            this.errorNotification(UserAlreadyExists, true)
            return
          }
          throw error
        }
      } else {
        this.errorNotification(AlreadyRegistered, true)
      }
    },
    onCheckout() {
      const chargeItems = []

      chargeItems.push({
        item_price_id: this.selectedPlan.chargebeeFrequency,
        quantity: 1,
      })

      this.selectedPlan.chargebeeAddons.forEach((addon) => {
        chargeItems.push({
          item_price_id: addon,
        })
      })

      this.selectedPlan.chargebeeCharges.forEach((charge) => {
        chargeItems.push({
          item_price_id: charge,
          charge_on_option: 'immediately',
        })
      })

      const subscription = {}
      try {
        ire('generateClickId', function (clickId) {
          subscription['cf_impact_click_id'] = clickId || 'test'
        })
      } catch (error) {
        console.log('error on impact_click_id event', error)
      }

      this.newSubscription(
        {
          subscription_items: chargeItems,
          coupon_ids: [...this.selectedPlan.chargebeeCoupon, ...this.coupons],
          customer: {
            email: this.email,
            ...this.separateName,
          },
          subscription,
        },
        {
          onSuccess: (hostedPageId) => {
            this.setLoading(true)
            this.registerWithSub({ hostedPageId, planId: this.selectedPlan.id })
              .then(() => {
                this.$posthog.sendEvent(EVENTS.subscription.create, {
                  'subscription plan id': this.selectedPlan.id,
                })
                FullStory.then((FS) => {
                  FS.event('subscription created', {
                    email_str: this.email,
                    promotion_name_str: this.affiliateCode
                      ? this.affiliateCode
                      : '',
                    date_started_str: moment().format('MM/DD/YYYY'),
                    env_str: process.env.VUE_APP_MODE,
                    fok_newsletter_opt_in_bool: this.generalEmailsOptin,
                  })
                })
                this.$store.dispatch('user/refreshUserData').then(() => {
                  this.setLoading(false)
                  this.$router.replace('/welcome')
                })
              })
              .catch(() => {
                this.setLoading(false)
                this.errorNotification(ServerError, true)
              })
          },
          onError: () => {
            this.setLoading(false)
            this.errorNotification(ServerError, true)
          },
          onClose: () => {},
        }
      )
    },
  },
  watch: {
    currentRegistrationStep: {
      handler: function (value) {
        const parentUrl = this.$route.matched[0]
        this.$router.replace({
          path: `${parentUrl.path}/${this.steps[value - 1].path}`,
          query: this.$route.query,
        })
      },
      immediate: true,
    },
    $route: {
      handler: function (to) {
        let urlStep = parseInt(to.path.match(/\d+/).join(''))

        if (this.currentRegistrationStep !== urlStep) {
          this.onStepChanged(urlStep)
        }
      },
      immediate: true,
    },
    planError(value) {
      if (value) {
        this.errorNotification(NoPlanError, true, 25)
      }
    },
  },
  mounted() {
    setTimeout(() => {
      hideIntercomWidget(true)
    }, 1000)
    credentialsHandler.getFbSdk()

    const stepsToResume = this.getRegistrationStepsToResume
    const plan = this.$route.query['plan']
    const step = this.$route.query['step']
    const queryCoupons = this.$route.query['coupons']

    if (plan) {
      this.loadCheckout(plan)
    } else {
      this.loadCheckout()
    }

    if (queryCoupons) {
      if (typeof queryCoupons === 'object') {
        this.coupons = [...queryCoupons]
      } else {
        this.coupons.push(queryCoupons)
      }
    }

    if (step) {
      this.$store.dispatch('registration/setRegistrationStep', Number(step))
    } else if (stepsToResume) {
      const goToStep = stepsToResume[0]
      if (goToStep) {
        this.$store.dispatch('registration/setRegistrationStep', goToStep.index)
      }
      if (goToStep.index === 1) {
        startExperiment(experiments.register)
      }
    }

    if (this.$routeQuery.get('email')) {
      this.setUserEmail(decodeURIComponent(this.$routeQuery.get('email')))
    }

    setTimeout(() => {
      this.show = true
      this.showStepsOnFirstStep = ExternalServices.ABTests.showStepsOnFirstStep
    }, 250)
  },
  destroyed() {
    if (!this.isMobile().any) {
      hideIntercomWidget(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.register {
  @include signup-bg;
}

.mp-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(-100vh+ #{$signup-top-section-height});
}

.header-text {
  font-size: $h4-font-size;
}

.credential-wrapper {
  min-height: calc(100vh - #{$signup-top-section-height * 4});
}

.registration-steps {
  display: none;
}

.registration-back-button {
  &:hover {
    color: $clr-navy-70 !important;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
  .fade-leave-to
  
  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
